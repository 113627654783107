import React, { useEffect, useState } from "react";
import { StatusIcon, useModal, ContentLoading } from "../../components";
import { useLazyQuery } from "@apollo/react-hooks";
import "react-table/react-table.css";
import styled from "styled-components";
import { Button, Grid, Link, Paper } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';

import { GetAllBuyerGroups, GetAllBuyerGroups_LDPConfigQueryGroup_BuyerGroup } from "../../common/models/types/GetAllBuyerGroups";
import { GET_ALL_BUYERGROUPS } from "../../common/models/buyergroups";
import { BuyerGroupFormModal } from "./buyergroupModal";

interface BuyerGroupProps {
  buyerGroupId: String;
}

const ViewBuyerGroup = ({ buyerGroupId, onRefreshList }: BuyerGroupProps) => {

  const [getBuyerGroup, { data, error, loading, called, refetch }] = useLazyQuery<GetAllBuyerGroups>(GET_ALL_BUYERGROUPS);
  const { Modal, closeModal, openModal } = useModal();

  useEffect(() => {
    getBuyerGroup({
      variables: { where: `BuyerGroupId = ${buyerGroupId}` },
    });
  }, []);

  return (
    <Paper style={Style.Paper}>
      {data ? (
        <BuyerGroupContent
          data={data?.LDPConfigQueryGroup?.BuyerGroup[0]}
          openModal={openModal}
          closeModal={closeModal}
          refetch={refetch}
        />
      ) : (
        <ContentLoading showTip={true} />
      )}
      <Modal />
    </Paper>
  );
};

export default ViewBuyerGroup;

interface BuyerGroupContentProps {
    data: GetAllBuyerGroups_LDPConfigQueryGroup_BuyerGroup;
    openModal: Function;
    closeModal: Function;
    setContent: Function;
    refetch: Function;
}

const BuyerGroupContent = ({
    data,
    openModal,
    closeModal,
    refetch,
  }: BuyerGroupContentProps) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Style.Info>
            <Style.Item>
              <span style={{ fontWeight: "bold" }}>Buyer Group ID: </span>
              <span>{data?.BuyerGroupId}</span>
            </Style.Item>
            <Style.Item>
              <span style={{ fontWeight: "bold" }}>Buyer Group Name: </span>
              <span>{data?.BuyerGroupName}</span>
            </Style.Item>
            <Style.Item>
              <span style={{ fontWeight: "bold" }}>Vertical ID: </span>
              <span>{data?.VerticalId}</span>
            </Style.Item>
            <Style.Item>
              <span style={{ fontWeight: "bold" }}>Sub Vertical Id: </span>
              <span>{data.SubVerticalId}</span>
            </Style.Item>
            <Style.Item>
              <span style={{ fontWeight: "bold" }}>Position: </span>
              <span>{data.Position}</span>
            </Style.Item>
            <Style.Item>
              <span style={{ fontWeight: "bold" }}>User ID: </span>
              <span>{data.UserId}</span>
            </Style.Item>
            <Style.Item>
              <span style={{ fontWeight: "bold" }}>Created Date: </span>
              <span>{data.CreatedDate}</span>
            </Style.Item>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                openModal({
                  title: "Edit Buyer Group",
                  icon: <EditIcon />,
                  iconColor: "violet",
                  content: (
                    <BuyerGroupFormModal
                      action={"edit"}
                      data={data}
                      close={closeModal}
                      refetch={refetch}
                    />
                  ),
                });
              }}
            >
              Edit
            </Button>
          </Style.Info>
        </Grid>
      </Grid>
    );
  };

const Style = {
  Paper: {
    padding: "0px",
    margin: "20px 0px",
    width: "100%",
  },
  Details: styled.span`
    margin-left: 10px;
  `,
  Info: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 5px 0px;
    overflow: hidden;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 10px;
    span {
      width: 50%;
      text-align: left;
      :first-child {
        text-align: right;
        margin-right: 20px;
      }
    }
  `,
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
