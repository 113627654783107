import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { ContentLoading, useModal } from "../../components";

import { GET_ALL_BUYERGROUPS } from "../../common/models/buyergroups";
import {
  GetAllBuyerGroups,
} from "../../common/models/types/GetAllBuyerGroups";
import { Box, Button, Grid, IconButton, Modal } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { toast } from "react-toastify";
import { AddCircle } from "@material-ui/icons";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ViewBuyerGroup from "./buyergroupView";
import { BuyerGroupFormModal } from "./buyergroupModal";

import { signalBuyerGroupRefreshCarriedOut } from "../../state/buyerGroupSectionReducer";
import { updateTabContainers } from "../../state/tabsReducer";
import { TabProps } from "../../components/tabs";

import LDPUIDataTable from "../../components/LDPUIDataTable";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { updateBuyerGroups } from "../../state/dataCacheReducer";
import { numberColumnFilter, stringColumnFilter } from "../../components/customMuiDatatableFilter";

interface BuyerGroupsProps {
  buyerGroupRecordSaved: boolean;
  dispatch: Function;
  buyerGroupTabs: any;
}

const TAB_CONTAINER = "buyergroup-list-tabs";

const BuyerGroupList = ({ buyerGroupTabs, buyerGroupRecordSaved, dispatch }: BuyerGroupsProps) => {
  const { Modal, closeModal, openModal } = useModal();
  const [getBuyerGroup, { data, error, loading, called, refetch }] = useLazyQuery<GetAllBuyerGroups>(GET_ALL_BUYERGROUPS);

  useEffect(() => {
    getBuyerGroup();
  }, []);

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch(
        updateTabContainers({
            [TAB_CONTAINER]: {
                tabFocus: (tabs.length - 1),
                tabs: tabs,
            }
        })
    );
  };

  const addTab = (title: string, content: JSX.Element) => {  
    var currentTabs = buyerGroupTabs.tabContainers[TAB_CONTAINER].tabs;
    dispatch(updateTabContainers({
      [TAB_CONTAINER]: {
        tabFocus: currentTabs.length,
        tabs: [
            ...currentTabs,
            {
              tabId: `buyergroup-${currentTabs.length}`,
              title,
              content,
              closeTab: handleCloseTab
            },
        ],
      }
    }));
  }

  useEffect(() => {
    if(data && !error){
      // update buyer group list cache
      dispatch && dispatch(
        updateBuyerGroups(data?.LDPConfigQueryGroup?.BuyerGroup || [])
      );
    }

    if (data && buyerGroupRecordSaved) {
      toast.info("Refreshing List");
      refetch().finally(() => {
        toast.dismiss();
        dispatch(signalBuyerGroupRefreshCarriedOut());
      });
    }
  }, [data]);

  const columnsMUI = [
    {
      name: "BuyerGroupId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <IconButton
              onClick={() =>
                addTab(`${tableMeta.tableData[tableMeta.rowIndex].BuyerGroupName}`, <ViewBuyerGroup buyerGroupId={value} />)
              }
            >
              <LaunchIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: "BuyerGroupId",
      label: "BuyerGroupId",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "BuyerGroupName",
      label: "BuyerGroupName",
      options: {
        viewColumns: false,
        filter: true,
        sort: true,
        ...stringColumnFilter
      },
    },

    {
      name: "Position",
      label: "Position",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "SubVerticalId",
      label: "SubVerticalId",
      options: {
        viewColumns: false,
        filter: true,
        sort: true,
        ...numberColumnFilter
      },
    },
    {
      name: "VerticalId",
      label: "VerticalId",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          /* return value
            ? mument(value)
              .local()
              .format("YYYY-MM-DD")
            : ""; */
            return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {
        viewColumns: false,
        filter: true,
        sort: true,
        ...stringColumnFilter
      },
    },
  ];

  const options: any = {
    searchOpen: true,
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          startIcon={<AddCircle />}
          onClick={() => {
            openModal({
              title: "Add New Buyer Group",
              icon: <GroupAddIcon />,
              iconColor: "violet",
              content: (
                <BuyerGroupFormModal
                  action={"create"}
                  data={null}
                  close={closeModal}
                  refetch={refetch}
                />
              ),
            });
          }}
        >
          Create New
        </Button>
      </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
            ldpTableId="buyer-group-list"
            restoreFilters={true}
            title={"BuyerGroups"}
            data={data?.LDPConfigQueryGroup?.BuyerGroup}
            columns={columnsMUI}
            options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: any) => ({
  buyerGroupRecordSaved: state.buyerGroupSection.buyerGroupRecordSaved,
  buyerGroupTabs: state.tabsSection,
}), null)(BuyerGroupList);

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
